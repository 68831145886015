.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  width: 230px;
  height: 230px;
  border-radius: 230px;
  background-color: rgb(255, 255, 255, 0.5);

  border: 0.1px solid;
  padding: 5px;
  box-shadow: 1px 3px 15px #afaeae;
  border-color: rgb(223, 217, 210);
}

.icon_btn {
  width: 100%;
  height: 45%;
  display: flex;
  justify-content:center;
  align-items: flex-end;
}

.text_btn {
  width: 80%;
  height: 55%;
  display: flex;
  justify-content:center;
  align-items: center;
  text-align: center;
}

.logo {
  width: 70px;
  height: 70px;
}

.text1 {
  font-size: 25px;
  color: #666;
}

.text2 {
  font-size: 25px;
  color: #b28a4c;
}

.text3 {
    font-size: 1.5em;
    color: #666;
  }
